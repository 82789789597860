import React from 'react';

const Section = ({ id, title, children, activeSection }) => {
  return (
    <section id={id} className={`${id} section ${activeSection === id ? 'active' : ''}`}>
      <div className="container">
        <div className='body-header'>
          <h2>{title}</h2>
        </div>
        <div className='body-wrapper'>
          {children}
        </div>
      </div>
    </section>
  );
};

export default Section;
