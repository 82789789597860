import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Hero from './Components/Hero';
import Blobs from './Components/Blobs';
import Navigation from './Components/Navigation';
import Info from './Components/Info';
import Footer from './Components/Footer';
import Lineup from './Pages/lineup';
import Contact from './Pages/contact';
import TicketPage from './Pages/tickets';
import './App.css';

const App = () => {
  const location = useLocation(); // Moved inside Router

  const getPageClass = () => {
    if (location.pathname === '/') {
      return 'home-page';
    } else if (location.pathname === '/tickets') {
      return 'tickets-page';
    } else if (location.pathname === '/contact') {
      return 'contact-page';
    } else {
      return 'default-page';
    }
  };

  return (
    <div className={`App ${getPageClass()}-page`}>
      <Helmet>
        <title>WASTELANDS FESTIVAL 2025</title>
        <meta name="title" content="Welcome to the Wastelands." />
        <meta name="description" content="Wastelands is a brand new music and arts festival in the heart of the Scottish Borders celebrating community, diversity and collaboration." />
      </Helmet>

      <Navigation />
      <Routes>
        <Route path="/" element={
          <>
            <Blobs />
            <Hero />
            <Info />
          </>
        } />

        <Route path="/lineup" element={<Lineup />} />
        <Route path="/tickets" element={<TicketPage />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>

      <Footer />
    </div>
  );
};

// Main entry point wrapped in Router
const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
