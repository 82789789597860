import React from 'react';
import '../css/footer.css'; 
import wlLogo from '../wastelands-logo-no-bg.png'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-left">
          <a href='/'>
          <img 
            src={wlLogo} 
            alt="Logo" 
            className="footer-logo"
          />
          </a>
        </div>
        <div className="footer-right">
          <div className="footer-links">
            <a href="/#info" className="footer-link">About Us</a>
            <a href="/contact" className="footer-link">Contact</a>
            {/* <a href="/contact" className="footer-link">Terms of Service</a> */}
          </div>
          <p className="footer-privacy">
            © 2024 Wastelands Festival. All rights reserved. 
            {/* <a href="/privacy" className="footer-link">Privacy Policy</a> */}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
