import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Navigation = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menu is closed by default
  const location = useLocation(); // Get the current path

  useEffect(() => {
    const scrollToHash = () => {
      const hash = location.hash; // Get the current hash
      if (hash) {
        const section = document.querySelector(hash);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the section
        }
      }
    };

    // Scroll to the hash if it exists on load or location change
    scrollToHash();
  }, [location]);

  useEffect(() => {
    const handleIntersection = (entries) => {
      // Sort entries based on how much they are intersecting
      const visibleSections = entries
        .filter((entry) => entry.isIntersecting)
        .sort((a, b) => {
          return b.intersectionRatio - a.intersectionRatio;
        });

      // Set active section to the one with the highest intersection ratio
      if (visibleSections.length > 0) {
        setActiveSection(visibleSections[0].target.id);
      }
    };

    const observerOptions = {
      root: null,
      threshold: [0.1, 0.25, 0.5, 0.75, 1], // Multiple thresholds for finer control
    };

    const observer = new IntersectionObserver(handleIntersection, observerOptions);

    const sections = document.querySelectorAll('.section');
    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, []);

  // Handle page changes based on the current location
  useEffect(() => {
    const path = location.pathname;

    // Map paths to sections for full-page routes
    switch (path) {
      case '/lineup':
        setActiveSection('lineup');
        break;
      case '/contact':
        setActiveSection('contact');
        break;
      case '/tickets':
        setActiveSection('tickets');
        break;
      default:
        setActiveSection('home'); // Default section
    }

    // Ensure that the menu is closed when the location changes
    setIsMenuOpen(false);
  }, [location]);

  // Toggle menu on mobile
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Close menu after clicking a link (for mobile behavior)
  // const closeMenu = () => {
  //   setIsMenuOpen(false);
  // };

  return (
    <nav className="links">
      <a href="/#home" className="nav-link-home-logo">
        WASTELANDS
      </a>
      <div className={`hamburger ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={`links-container ${isMenuOpen ? 'active' : ''}`}>
        <a
          href="/#info"
          className={`nav-link ${activeSection === 'info' ? 'active' : ''}`}
          
        >
          Info
        </a>
        <a
          href="/lineup"
          className={`nav-link ${activeSection === 'lineup' ? 'active' : ''}`}
          
        >
          Lineup
        </a>
        <a
          href="/contact"
          className={`nav-link ${activeSection === 'contact' ? 'active' : ''}`}
          
        >
          Contact
        </a>
        <a
          href="/tickets"
          className={`nav-link ${activeSection === 'tickets' ? 'active' : ''} nav-link-highlight`}
          
        >
          Tickets
        </a>
      </div>
    </nav>
  );
};

export default Navigation;
