import React from 'react';

const Blobs = () => {
  return (
    <div className="blob-container">
      <div className="blob-container-right">
        <div className="blob blob_snowflake"></div>
        <div className="blob blob_doublestripe"></div>
        <div className="blob blob_arrowsquiggle"></div>
        <div className="blob blob_arrowbent"></div>
        <div className="blob blob_Highlight"></div>
        <div className="blob blob_mellowsquiggle"></div>
        <div className="blob blob_waves"></div>
        <div className="blob blob_zigzag"></div>
        {/* <div className="blob blob_circle"></div> */}
    </div>
    <div className="blob-container-left">
        <div className="blob blob_bigsquiggle"></div>
        <div className="blob blob_arrowstraight"></div>
        <div className="blob blob_dot"></div>
        <div className="blob blob_doublezag"></div>
        <div className="blob blob_bendlong"></div>
        <div className="blob blob_dubbend"></div>
        <div className="blob blob_circle_left"></div>
        {/* <div className="blob blob_"></div>
        <div className="blob blob_"></div> */}
    </div>
    <div className='blob-mask'></div>
  </div>
  );
};

export default Blobs;